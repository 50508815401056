:root {
  --sl-soda: #0dbbee;
  --sl-blueberry: #000b42;
  --sl-city: #858ba3;
  --sl-breeze: #cce1f2;
  --sl-cool: #f3f3f3;

  --sl-danger: #ff4a4a;
  --sl-blue-3: #000b42;
  --sl-red-1: #ff4a4a;
  --sl-gray-3: #fbfbfb;
}
