$mat-spotliked: (
  50: #82def8,
  100: #6ad8f6,
  200: #51d1f5,
  300: #39cbf3,
  400: #21c5f2,
  500: #0dbbee,
  600: #0ca9d4,
  700: #0b96bc,
  800: #0a82a4,
  900: #086f8c,
  A100: #847cdb,
  A200: #a06edd,
  A400: #ec5569,
  A700: #e63950,
  contrast: (
    50: #444,
    100: #444,
    200: #444,
    300: #444,
    400: #444,
    500: white,
    600: white,
    700: white,
    800: #999,
    900: #999,
    A100: #444,
    A200: white,
    A400: white,
    A700: white,
  ),
);

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$spotliked-webapp-primary: mat.define-palette($mat-spotliked);
$spotliked-webapp-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$spotliked-webapp-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$spotliked-webapp-theme: mat.define-light-theme(
  (
    color: (
      primary: $spotliked-webapp-primary,
      accent: $spotliked-webapp-accent,
      warn: $spotliked-webapp-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($spotliked-webapp-theme);

mat-dialog-container.mat-dialog-container {
  padding: 0;
}
