/* colors */
@import './core/scss/colors.scss';

/* variables */
@import './core/scss/variables.scss';

/* Bootstrap */
@import './core/scss/bootstrap.scss';

/* Material */
@import './core/scss/material.scss';

/* Animations */
@import './core/scss/animation.scss';

/* google fonts */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

html,
body {
  height: 100%;
  --bs-body-font-size: 14px;
}

body {
  margin: 0;
  font-family: 'DM Sans', Roboto, 'Helvetica Neue', sans-serif;
}

body.mat-typography :where(h1, h2, h3, h4, h5, h6, p, span, div, a) {
  font-family: 'DM Sans', Roboto, 'Helvetica Neue', sans-serif;
}

.cdk-global-overlay-wrapper .mat-dialog-container {
  border-radius: 12px;
  transform: translateZ(0);
}

a.text-link {
  color: black;
  text-decoration: none;
  border-bottom: solid 1px black;
  transition: color 250ms ease, border-color 250ms ease;

  &:hover {
    color: var(--sl-soda);
    border-color: var(--sl-soda);
  }
}

sl-tab-navigation .navigation-mobile .mat-tab-label {
  min-width: 60px;
  padding: 0;
}
